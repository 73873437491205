import React from 'react'
import { graphql, Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import LayoutRoot from '../components/LayoutRoot'
import styles from '../styles/foodRecipe.module.css'
import classnames from 'classnames'
import Img, { FixedObject, FluidObject } from 'gatsby-image'
import { DiscussionEmbed } from "disqus-react"

/**
 *   This is the return format for a single recipe query
 *   Try here http://localhost:8000/___graphql
 
 query MyQuery {
    strapiRecipe {
        created_at
        id
        description
        strapiId
    }
}

Returns 
{
  "data": {
    "strapiRecipe": {
      "created_at": "2021-02-18T20:54:52.663Z",
      "id": "Recipe_1",
      "description": "Healthy oatmeal chocolate chip cookie",
      "strapiId": 1
    }
  },
  "extensions": {}
}
 */

export interface TagProps {
  slug: string
  name: string
}

export interface FoodRecipeProps {
    data: {
        strapiRecipe: {
            id: number
            strapiId: string
            name: string
            description: string
            slug: string
            level: string
            totalTime: string
            servingSize: number
            ingredients: string
            amazonAds: string[]
            instructions: string
            tags: TagProps[]
            images: {
              childImageSharp: {
                fixed: FixedObject
                fluid: FluidObject
              }
            }
        }
    }
}

export const query = graphql`
  query Recipe($id: Int!) {
    strapiRecipe(strapiId: { eq: $id }) {
        id
        strapiId
        name
        description
        slug
        level
        totalTime
        servingSize
        ingredients
        amazonAds
        instructions
        tags {
          slug
          name
        }
        images {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
    }
  }
`

const FoodRecipePage: React.FC<FoodRecipeProps> = ({ data }) => {
    const singleFoodRecipe = data.strapiRecipe

    const disqusConfig = {
      shortname: 'canary-giant',
      config: { identifier: singleFoodRecipe.slug}
    }
    
    var tags: any[] = []
    singleFoodRecipe.tags.forEach((item, index) => {
        const url = `/tags/${item.slug}`
        tags.push(<div key={index}><Link className={styles.tagLink} to={url}>{item.name}</Link></div>)
    })

    var amazonAds: any[] = []
    singleFoodRecipe.amazonAds.forEach((item) => {
      amazonAds.push(
        <iframe className={classnames(styles.iframeAds)} marginwidth="0" marginheight="0" scrolling="no" frameborder="0"  
        src={item}>  
        </iframe>
      )
    })

    return (
      <LayoutRoot>
      <div className={classnames(styles.container)}>
        <div className={classnames(styles.topSection)}>
          <h1 className={classnames(styles.h1)}>{singleFoodRecipe.name}</h1>
          <div id="shortInformation" className={classnames(styles.shortInformationBox)}>
            <li>
                  <span className={classnames(styles.bold)}>Level: </span>
                  <span>{singleFoodRecipe.level}</span>
            </li>
            <li>
              <span className={classnames(styles.bold)}>Total Time: </span>
              <span>{singleFoodRecipe.totalTime}</span>
            </li>
            <li>
              <span className={classnames(styles.bold)}>Serving Size: </span>
              <span>{singleFoodRecipe.servingSize}</span>
            </li>
          </div>

            <div id="tags" className={styles.tag}>
              {tags}
            </div>
            <Img className={classnames(styles.image)} fluid={singleFoodRecipe.images.childImageSharp.fluid} />

            <p className={classnames(styles.description)}>
            {singleFoodRecipe.description}
           </p>

         </div>
        
          <div className={styles.text}>
            <h3 className={classnames(styles.h)}>Ingredients</h3>
            <ReactMarkdown source={singleFoodRecipe.ingredients} />
          </div>

          <div id="amazonAds">
             {amazonAds}
          </div>

          <div className={classnames(styles.text, styles.instruction)}>
            <div>
              <h3 className={classnames(styles.h)}>Instructions</h3>
              <ReactMarkdown source={singleFoodRecipe.instructions} />
            </div>           
          </div>

          <DiscussionEmbed {...disqusConfig} />
    </div>
    </LayoutRoot>
  )
}

export default FoodRecipePage